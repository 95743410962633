<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner pt-6">
      <v-card class="px-8 py-12">
        <Tip :dialog="dialog" @closeForm="closeTip"></Tip>
        <v-row>
          <v-col cols="3">
            <v-img src="../../assets/images/logos/LogoOrange.png" max-width="40px"></v-img>
          </v-col>
          <v-col cols="9">
            <div
              class="d-flex justify-center"
              style="font-size: 25px; font-weight: bold; margin-left: -80px; margin-top: 3px"
            >
              <span>パスワード設定</span>
            </div>
          </v-col>
        </v-row>
        <template v-if="apierror.status === 'error'">
          <div v-for="msg of apierror.messages" :key="msg">
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
        <div class="my-6">
          <span>メールアドレスを入力してください</span>
        </div>
        <v-text-field
          v-model="$v.Form.email.$model"
          :error-messages="formEmailErrors"
          hide-details="auto"
          placeholder="メールアドレス"
          outlined
          dense
          color="primary"
          @keydown.enter="VerifyEmail2"
        ></v-text-field>
        <v-row class="mb-0 mt-0" style="height: 50px" no-gutter>
          <v-spacer></v-spacer>
          <v-col cols="8" class="d-flex justify-end" style="text-decoration: underline; color: #ff6e40">
            <div class="pointer">
              <span class="mr-3" @click="$router.push('/register')"> 新規登録 </span>
            </div>
            <div class="pointer">
              <span @click="$router.push('/login')"> ログイン </span>
            </div>
          </v-col>
        </v-row>
        <v-btn depressed block color="primary" :loading="submitStatus" class="mt-2" @click="VerifyEmail2">
          <span style="color: #fff">送信</span>
        </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { email, required } from 'vuelidate/lib/validators'
// eslint-disable-next-line import/no-extraneous-dependencies

import Tip from './TipForgotPassword.vue'

export default {
  components: {
    Tip,
  },
  data: () => ({
    Form: {
      email: '',
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    submitStatus: false,

    dialog: false,
  }),
  computed: {
    formEmailErrors() {
      const errors = []
      if (!this.$v.Form.email.$dirty) return errors
      // eslint-disable-next-line
      !this.$v.Form.email.required && errors.push('メールアドレスを入力してください。')
      // eslint-disable-next-line
      !this.$v.Form.email.email && errors.push('メールアドレスを正しく入力してください。')

      return errors
    },
  },
  validations: {
    Form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    ...mapActions('auth', ['ForgotPassword']),

    VerifyEmail2() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.ForgotPassword(this.Form)
          .then(() => {
            this.submitStatus = false
            this.dialog = true
          })
          .catch(error => {
            this.submitStatus = false
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          })
      }
    },
    closeTip() {
      this.dialog = false
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.pointer :hover {
  cursor: pointer;
}
</style>
